import { glyphAPI } from './glyphAPI';

export interface GenerateImagePayload {
  prompt: string;
}

export const generateImage = async (payload: GenerateImagePayload): Promise<string> => {
  console.log('generateImage payload', payload);
  const res = await glyphAPI<any, GenerateImagePayload>('image/dalle', {
    method: 'POST',
    body: payload,
  });

  return res.image;
};